function startPreloader() {

  setTimeout(() => {
    $('.preloader-it > .la-anim-1').addClass('la-animate');
    $(".preloader-it").delay(2000).fadeOut("slow");
  }, 500);


  $('.nicescroll-bar').slimscroll({
    height: '100%',
    color: '#878787',
    disableFadeOut: true,
    borderRadius: 0,
    size: '4px',
    alwaysVisible: false
  });

  $('.app-nicescroll-bar').slimscroll({
    height: '162px',
    size: '4px',
    color: '#878787',
    disableFadeOut: true,
    borderRadius: 0
  });
  var height, width, clickAllowed;

  height = $(window).height();
  width = $(window).width();

  // flag to allow clicking
  clickAllowed = true;

  $('.full-height').css('height', (height));
  $('.page-wrapper').css('min-height', (height));
  $('#chat_list_scroll').css('height', (height - 149));
  $('.fixed-sidebar-right .chat-content').css('height', (height - 158));

  /*Right Sidebar Scroll Start*/
  if (width <= 1007) {
    clickAllowed = true;
  } else {
    clickAllowed = false;
  }
  /*Right Sidebar Scroll End*/
}

function toggleMenuItens() {
  /*Sidebar Collapse Animation*/
  var sidebarNavCollapse = $('.fixed-sidebar-left .side-nav  li .collapse');
  var sidebarNavAnchor = '.fixed-sidebar-left .side-nav  li a';
  $(document).on("click", sidebarNavAnchor, function (e) {
    if ($(this).attr('aria-expanded') === "false")
      $(this).blur();
    $(sidebarNavCollapse).not($(this).parent().parent()).collapse('hide');
  });
}

function registerBodyClickToCloseRightSideBarFilter() {
  $(document).mouseup(function (e) {
    var container = $(".fixed-sidebar-right");
    var selectPanel = $(".mat-select-panel");

    // if the target of the click isn't the container nor a descendant of the container
    if ((!container.is(e.target) && container.has(e.target).length === 0) &&
      (!selectPanel.is(e.target) && selectPanel.has(e.target).length === 0)) {
      $(".wrapper").removeClass('open-right-sidebar');
    }
  });
}

function setHeightWidth() {
  var height, width, clickAllowed;

  height = $(window).height();
  width = $(window).width();

  // flag to allow clicking
  clickAllowed = true;

  $('.full-height').css('height', (height));
  $('.page-wrapper').css('min-height', (height));
  $('#chat_list_scroll').css('height', (height - 149));
  $('.fixed-sidebar-right .chat-content').css('height', (height - 158));

  /*Right Sidebar Scroll Start*/
  if (width <= 1007) {
    clickAllowed = true;
  } else {
    clickAllowed = false;
  }
  /*Right Sidebar Scroll End*/
}
